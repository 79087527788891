import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppWrapper from './App';
import SnakeGame from './snake';
import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router';
import {
    BrowserRouter,
    Route,
    Link,
    NavLink,
    Switch,
    Redirect
} from 'react-router-dom';

import snakeImage from './snek.png';
import ticImage from './tic.png';

class Navbar extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <nav className="navbar">
                <div class="nav-content">
                    <a id="title">Florin Peltea</a>
                    <NavLink exact to="/" className="nav-item" activeClassName="nav-item-active">Home</NavLink>
                    <NavLink to="/projects" className="nav-item" activeClassName="nav-item-active">Projects</NavLink>
                    <NavLink to="/contact" className="nav-item" activeClassName="nav-item-active">Contact</NavLink>
                </div>
            </nav >
        )
    }
}

class Frame extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="frame">
                <div className="view-frame">
                    <Route exact path="/" component={Home} />

                    <Route exact path="/projects" component={Projects} />

                    <Route path="/projects/snake" component={SnakePage} />
                    <Route path="/projects/tic" component={TicPage} />

                    <Route path="/contact" component={Contact} />
                </div>
            </div>
        )
    }
}

class Home extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="about">
                Welcome to my website.<br />
                <p />You can view my projects on my <Link to="/projects">projects page</Link> as well as on my <a href="https://github.com/fpeltea">GitHub profile</a>.
            </div>
        )
    }
}

class Projects extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="projects">
                <div className="project-list">
                    <Link to="/projects/snake" className="no-decoration-link">
                        <div className="project-item">
                            <img id="snakeImage" src={snakeImage} />
                            <div className="project-desc">
                                <div>Snake game with high scores <br /> (requires keyboard to play)</div>
                            </div>
                        </div>
                    </Link>
                    <Link to="/projects/tic" className="no-decoration-link">
                        <div className="project-item">
                            <img id="ticImage" src={ticImage} />
                            <div className="project-desc">
                                <div>Online multiplayer tic-tac-toe game</div>
                            </div>
                        </div>
                    </Link>

                </div>

            </div>
        )
    }
}

class Contact extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="contact">
                You can contact me via e-mail at:<br />
                pelteaflorinalexandru@gmail.com
            </div>
        )
    }
}

class SnakePage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="snake-page">
                <SnakeGame />
                <div>
                    Use the arrow keys and the enter key to navigate the menu. <br />
                    Use the arrow keys in-game to change direction. <br />
                    A keyboard is required to play (sorry, mobile users) <br />

                    <p />The high score server is currently not functional.<br />

                    <p />This game was made in JavaScript using the HTML5 canvas element. jQuery is used to fetch and send high scores.<br />
                    The backend for the high score system is a Node.js app with a MongoDB database.<br />
                </div>
            </div>
        )
    }
}

class TicPage extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="tic-page">
                <AppWrapper />
                <div className="tic-description">
                    Click "Find Game" to search for an opponent.<br />
                    You can play against yourself to test the game by opening this page in two browser tabs at once.<br />

                    <p />The client for this game was made as a React component using Redux to store the state.<br />
                    The server is a Node.js app with a Redis database that stores game states, hosted on AWS EC2.
                </div>

            </div>
        )
    }
}

ReactDOM.render(
    <BrowserRouter>
        <Navbar />
        <Frame />
    </BrowserRouter>,
    document.getElementById('root')
);

//ReactDOM.render(<Frame />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
