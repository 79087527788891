import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ReactRedux, { Provider, connect } from 'react-redux';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import logo from './logo.svg';
import './App.css';

//Redux v
const MOVE = 'MOVE';
const DISPLAY = 'DISPLAY';
const GAMEID = 'GAMEID';
const PLAYERID = 'PLAYERID';
const START = 'START';
const STOP = 'STOP';
const TURN = 'TURN';
const SHAPE = 'SHAPE';
const RESET = 'RESET';
const defaultState = {
  spaces: [null, null, null, null, null, null, null, null, null],
  playerShape: "X",
  currentTurn: "X",
  gameOngoing: false,
  gameID: null,
  playerID: null,
  display: "Online Tic-Tac-Toe"
}

function move(space, shape) {
  return {
    type: MOVE,
    space: space,
    shape: shape
  }
}

function gameID(uuid) {
  return {
    type: GAMEID,
    gameID: uuid
  }
}

function playerID(uuid) {
  return {
    type: PLAYERID,
    playerID: uuid
  }
}

function display(message) {
  return {
    type: DISPLAY,
    message: message
  }
}

function start() {
  return {
    type: START
  }
}

function stop() {
  return {
    type: STOP
  }
}

function shape(shape) {
  return {
    type: SHAPE,
    shape: shape
  }
}

function turn(shape) {
  return {
    type: TURN,
    shape: shape
  }
}

function reset() {
  return {
    type: RESET
  }
}

function reducer(state = defaultState, action) {
  switch (action.type) {
    case (MOVE):
      const newSpaces = state.spaces.slice();
      newSpaces[action.space] = action.shape;
      if (state.currentTurn === "X") {
        return { ...state, spaces: newSpaces, currentTurn: "O" };
      } else if (state.currentTurn == "O") {
        return { ...state, spaces: newSpaces, currentTurn: "X" };
      }
      break;
    case (DISPLAY):
      return { ...state, display: action.message };
      break;
    case (GAMEID):
      return { ...state, gameID: action.gameID };
      break;
    case (PLAYERID):
      return { ...state, playerID: action.playerID };
      break;
    case (START):
      return { ...state, gameOngoing: true };
      break;
    case (STOP):
      return { ...state, gameOngoing: false };
      break;
    case (TURN):
      return { ...state, currentTurn: action.shape };
      break;
    case (SHAPE):
      return { ...state, playerShape: action.shape };
      break;
    case (RESET):
      return { ...state, ...defaultState };
      break;
    default:
      return state;
  }
}

const store = createStore(reducer);

////////////
// React:

class Board extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(space) {
    if (this.props.currentTurn === this.props.playerShape && this.props.spaces[space] === null && this.props.gameOngoing === true) {
      this.props.makeMove(space, this.props.playerShape);
      let message = {
        requestType: "move",
        space: space,
        playerShape: this.props.playerShape,
        gameID: this.props.gameID,
        playerID: this.props.playerID
      }
      ws.send(JSON.stringify(message));
      // return state;
    }
  }

  render() {
    return (
      <div className="board">
        <Space value={this.props.spaces[0]} onClick={() => this.handleClick(0)} />
        <Space value={this.props.spaces[1]} onClick={() => this.handleClick(1)} />
        <Space value={this.props.spaces[2]} onClick={() => this.handleClick(2)} />

        <Space value={this.props.spaces[3]} onClick={() => this.handleClick(3)} />
        <Space value={this.props.spaces[4]} onClick={() => this.handleClick(4)} />
        <Space value={this.props.spaces[5]} onClick={() => this.handleClick(5)} />

        <Space value={this.props.spaces[6]} onClick={() => this.handleClick(6)} />
        <Space value={this.props.spaces[7]} onClick={() => this.handleClick(7)} />
        <Space value={this.props.spaces[8]} onClick={() => this.handleClick(8)} />
      </div>
    );
  }
}

class Space extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="space" onClick={() => this.props.onClick()}>{this.props.value}</div>
    );
  }
}

class Status extends React.Component {
  constructor(props) {
    super(props);
  }

  renderTurn() {
    if (this.props.currentTurn == this.props.playerShape && this.props.gameOngoing === true) {
      return (
        <span id="statusTurn">
          Your turn!
        </span>
      )
    } else if (this.props.gameOngoing === true) {
      return (
        <span id="statusTurn">
          Your opponent's turn!
        </span>
      )
    }
  }

  renderShape() {
    if (this.props.gameOngoing === true) {
      return (
        <span id="statusShape">
          You are {this.props.playerShape}!
          </span>
      )
    } else {
      return (
        <div className="status">
          <span id="statusTitle">
            {this.props.display}
          </span>
        </div>
      )
    }
  }

  render() {
    return (
      <div className="status">
        {this.renderShape()}
        {this.renderTurn()}
      </div>
    )
  }
}

class Panel extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div id={this.props.gameOngoing === false ? "findGameButton" : "findGameButtonInactive"} onClick={() => connectSocket()
      }> FIND GAME</div >
    )
  }
}

class Game extends React.Component {
  constructor(props) {
    super(props)
  }

  handleClick(space) {
    this.props.makeMove(space, this.props.playerShape)
  }

  render() {
    return (
      <div className="game">
        <Status currentTurn={this.props.currentTurn} playerShape={this.props.playerShape} gameOngoing={this.props.gameOngoing} display={this.props.display} />
        <Board spaces={this.props.spaces} makeMove={this.props.makeMove} playerShape={this.props.playerShape} gameID={this.props.gameID} playerID={this.props.playerID} gameOngoing={this.props.gameOngoing} currentTurn={this.props.currentTurn} />
        <Panel gameOngoing={this.props.gameOngoing} />
      </div>
    )
  }
}
///////////////
// React-Redux:

function mapStateToProps(state) {
  return { spaces: state.spaces, playerShape: state.playerShape, currentTurn: state.currentTurn, gameOngoing: state.gameOngoing, display: state.display, gameID: state.gameID, playerID: state.playerID };
}

function mapDispatchToProps(dispatch) {
  return {
    makeMove: function (space, shape) {
      dispatch(move(space, shape));
    }
  }
}

const ConnectedGame = connect(mapStateToProps, mapDispatchToProps)(Game);

class AppWrapper extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Provider store={store}>
        <ConnectedGame />
      </Provider>
    )
  }

  componentWillUnmount() {
    store.dispatch(reset());
    if (ws !== undefined) {
      if (ws.readyState < 3) {
        ws.close();
      }
    }
  }

  componentDidMount() {
    store.dispatch(reset());
  }
}


ReactDOM.render(<AppWrapper />, document.getElementById("root"));
////

var ws;

function connectSocket() {
  if (ws !== undefined) {
    if (ws.readyState < 3) {
      return;
    }
  }

  ws = new WebSocket("wss://tic.florinpeltea.net:3000");
  store.dispatch(display("Connecting..."));

  ws.heartbeat = function () {
    if (this.pingTimeout != undefined) {
      clearTimeout(this.pingTimeout);
    }

    this.pingTimeout = setTimeout(() => {
      this.close();
    }, 5000 + 1000);
  }

  ws.onopen = function (event) {
    ws.heartbeat();
    let message = {
      requestType: "findGame"
    };
    ws.send(JSON.stringify(message));
    store.dispatch(display("Finding game..."));
  }

  ws.onmessage = function (event) {
    var parsedMessage = JSON.parse(event.data);
    ws.heartbeat();

    switch (parsedMessage.messageType) {
      case "newGameInfo":
        store.dispatch(reset());
        store.dispatch(display("Waiting for an opponent..."));
        store.dispatch(gameID(parsedMessage.gameID));
        store.dispatch(playerID(parsedMessage.playerID));
        store.dispatch(shape(parsedMessage.playerShape));
        store.dispatch(turn(parsedMessage.currentTurn));
        break;

      case "foundGameInfo":
        store.dispatch(reset());
        store.dispatch(display("Found a game!"));
        store.dispatch(gameID(parsedMessage.gameID));
        store.dispatch(playerID(parsedMessage.playerID));
        store.dispatch(shape(parsedMessage.playerShape));
        store.dispatch(turn(parsedMessage.currentTurn));
        store.dispatch(start());
        break;

      case "foundPlayer":
        store.dispatch(display("Found an opponent!"));
        store.dispatch(start());
        break;

      case "moveNotify":
        store.dispatch(move(parsedMessage.space, parsedMessage.shape));
        if (parsedMessage.endResult === store.getState().playerShape) {
          ws.close();
          store.dispatch(display("You win!"));
          store.dispatch(stop());
        } else if ((parsedMessage.endResult === "X" || parsedMessage.endResult === "O") && parsedMessage.endResult != store.getState().playerShape) {
          ws.close();
          store.dispatch(display("You lose!"));
          store.dispatch(stop());
        } else if (parsedMessage.endResult === "DRAW") {
          ws.close();
          store.dispatch(display("Draw!"));
          store.dispatch(stop());
        }
        break;

      case "moveConfirm":
        if (parsedMessage.endResult === store.getState().playerShape) {
          ws.close();
          store.dispatch(display("You win!"));
          store.dispatch(stop());
        } else if ((parsedMessage.endResult === "X" || parsedMessage.endResult === "O") && parsedMessage.endResult != store.getState().playerShape) {
          ws.close();
          store.dispatch(display("You lose!"));
          store.dispatch(stop());
        } else if (parsedMessage.endResult === "DRAW") {
          ws.close();
          store.dispatch(display("Draw!"));
          store.dispatch(stop());
        }
        break;

      case "ping":
        let pongMessage = {
          requestType: "pong"
        }
        ws.send(JSON.stringify(pongMessage));
        break;

      case "opponentDropped":
        ws.close();
        store.dispatch(stop());
        if (store.getState().display != "You win!" || store.getState().display != "You lose!" || store.getState().display != "Draw!") {
          store.dispatch(display("Opponent has disconnected."));
        }
        break;
    }
  }

  ws.onclose = function () {
    store.dispatch(stop());
    // store.dispatch(display("No connection to server."));
  };

}

export default AppWrapper;